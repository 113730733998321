
import { defineComponent } from 'vue'

import { auth, me } from '@/api/users'

export default defineComponent({
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      error: "",
    }
  },
  mounted() {
    me()
    .then(() => this.$router.push('/'))
    .catch(() => null)
  },
  methods: {
    submit() {
      auth(this.username, this.password).then(() => {
        this.$router.push('/')
      })
      .catch((err) => {
        const code = err.response.status
        if (code === 400 || code === 401) {
          this.error = "Incorrect password"
        } else {
          this.error = "Unexpected error"
        }
      })
    }
  },
})
