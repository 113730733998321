
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchBar',
  data() {
    return {
      searchText: ''
    }
  },
  methods: {
    submit() {
      this.$emit("searchSubmit", this.searchText)
    }
  },
});

