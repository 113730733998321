
import { defineComponent } from 'vue'
import { InputImage } from '@/api/images'
import mediaApi from '@/api/media'

interface Data {
  image: InputImage
  buttonText: string
}

const DEFAULT_BUTTON_TEXT = "Save"
const UPLOADING_BUTTON_TEXT = "Uploading..."

export default defineComponent({
  data(): Data {
    return {
      image: {
        title: "",
        description: "",
        media_id: "",
      },
      buttonText: DEFAULT_BUTTON_TEXT,
    }
  },
  methods: {
    submit() {
      const image = {
        title: this.image.title,
        description: this.image.description,
        media_id: this.image.media_id,
      }
      this.$emit('submit', image);
      this.clear()
    },
    clear() {
      this.image.title = ""
      this.image.description = ""
      this.image.media_id = ""
      const fileInput = this.$refs.fileInput as any
      fileInput.value = null
    },
    handleFileUpload(event: any) {
      this.buttonText = UPLOADING_BUTTON_TEXT
      const file = event.target.files[0];
      const data = new FormData();
      data.append('file', file, file.name);
      mediaApi.upload(data).then((resp: string) => {
        this.image.media_id = resp
        this.buttonText = DEFAULT_BUTTON_TEXT
      })
    },
  },
  computed: {
    isSubmitDisabled(): boolean {
      if (this.image.title === "" || this.image.media_id === "") {
        return true
      }
      return false
    }
  },
})
