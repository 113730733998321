
import { defineComponent, PropType } from 'vue';
import { Image } from '@/api/images';

export default defineComponent({
  name: 'ImageCard',
  props: {
    image: {
      type: Object as PropType<Image>,
      required: true,
    },
  },
  methods: {
    deleteImage() {
      this.$emit('deleteImage', this.image.id)
    },
    copyToClipboard(link: string) {
      if (!link.startsWith('http')) {
        link = location.host + link
      }
      navigator.clipboard.writeText(link)
    }
  },
});
