
import { defineComponent } from 'vue';

import SearchBar from './SearchBar.vue'

export default defineComponent({
  name: 'PageHeader',
  components: {
    SearchBar,
  },
  methods: {
    searchSubmit(text: string) {
      this.$emit("searchSubmit", text)
    }
  },
});

